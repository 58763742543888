import { Injectable } from '@angular/core';
import {
  SessionActions,
  PaymentRulesActions,
} from '@arc/unified-payments-frontend/shared-stores';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { User, AuthUserStore } from '@arc/arc-shared-frontend';
import { Store, select } from '@ngrx/store';
import { DataDogService } from '../../services/data-dog.service';

@Injectable()
export class DatadogEffects {
  constructor(
    private userStore$: Store<User>,
    private paymentRulesActions$: Actions<PaymentRulesActions.PaymentRulesActionsUnion>,
    private sessionActions$: Actions<SessionActions.SessionActionsUnion>,
    private datadogService: DataDogService,
  ) {}

  recordUserTags$ = createEffect(
    () =>
      this.sessionActions$.pipe(
        ofType(SessionActions.UpdateRUMSessionTagsAction.type),
        withLatestFrom(this.userStore$.pipe(select(AuthUserStore))),
        exhaustMap(([, user]) => {
          this.datadogService.recordUser(user.user);
          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  recordUserTenantTags$ = createEffect(
    () =>
      this.sessionActions$.pipe(
        ofType(SessionActions.UpdateRUMSessionTagsAction.type),
        withLatestFrom(this.userStore$.pipe(select(AuthUserStore))),
        exhaustMap(([, user]) => {
          this.datadogService.recordUserTenant(user.activeTenant);
          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  recordUserSessionTags$ = createEffect(
    () =>
      this.sessionActions$.pipe(
        ofType(SessionActions.UpdateRUMSessionTagsAction.type),
        map((action) => {
          this.datadogService.recordUserSession(action.payload);
          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  recordUserPaymentRulesTags$ = createEffect(
    () =>
      this.paymentRulesActions$.pipe(
        ofType(PaymentRulesActions.UpdateRUMPaymentRulesTagsAction.type),
        map((action) => {
          this.datadogService.recordUserPaymentRules(action.payload);
          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  recordUserPaymentTags$ = createEffect(
    () =>
      this.sessionActions$.pipe(
        ofType(SessionActions.UpdateRUMPaymentTagsAction.type),
        map((action) => {
          this.datadogService.recordUserPayment(action.payload);
          return EMPTY;
        }),
      ),
    { dispatch: false },
  );
}
