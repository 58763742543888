import { Injectable } from '@angular/core';
import { AppConfigSettings, User, UserTenant } from '@arc/arc-shared-frontend';
import {
  GetSessionSummaryResponse,
  SessionPayment,
  PaymentRulesResponseV1,
} from '@arc/unified-payments-frontend/shared-payment';
import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum';

@Injectable({
  providedIn: 'root',
})
// https://docs.datadoghq.com/real_user_monitoring/
export class DataDogService {
  private defaultPrivacyLevel: DefaultPrivacyLevel =
    DefaultPrivacyLevel.MASK_USER_INPUT;

  private applicationId: string;
  private clientToken: string;
  private environment: string;
  private site: string;
  private service: string;
  private version: string;
  private initialized = false;
  private sessionReplayEnabled = false;
  private sessionReplaySampleRate = 0;

  constructor(private appConfigSetting: AppConfigSettings) {
    this.applicationId = this.appConfigSetting.getconfig(
      'dataDogApplicationId',
    );
    this.clientToken = this.appConfigSetting.getconfig('dataDogClientToken');
    this.site = this.appConfigSetting.getconfig('dataDogSite');
    this.service = this.appConfigSetting.getconfig('domainName');
    this.environment = this.appConfigSetting.getconfig('environment');
    this.version = this.appConfigSetting.getconfig('releaseTag');
    this.sessionReplayEnabled =
      this.appConfigSetting.getconfig('dataDogSessionReplayEnabled') === 'true';
    this.sessionReplaySampleRate = +this.appConfigSetting.getconfig(
      'dataDogSessionReplaySampleRate',
    );
  }

  initializeRum(): void {
    try {
      datadogRum.init({
        applicationId: this.applicationId,
        clientToken: this.clientToken,
        site: this.site,
        service: this.service,
        env: this.environment,
        version: this.version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: this.sessionReplaySampleRate, // Default: 20, 0 Disables Session Replays
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: this.defaultPrivacyLevel,
      });
      this.initialized = true;
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.error('Failed to initialize DataDog', error);
    }

    if (this.sessionReplayEnabled) {
      datadogRum.startSessionReplayRecording();
    }
  }

  public recordUser(user: User): void {
    datadogRum.setUserProperty('id', user.userId);
    datadogRum.setUserProperty('role', user.role);
    datadogRum.setUserProperty('teamName', 'checkout');
  }

  public recordUserTenant(user: UserTenant): void {
    datadogRum.setUserProperty('tenantId', user.tenantId);
    datadogRum.setUserProperty('tenantName', user.tenantName);
    datadogRum.setUserProperty('tenantType', user.tenantType);
  }

  public recordUserSession(session: GetSessionSummaryResponse): void {
    datadogRum.setUserProperty('pesession', {
      sessionid: session.sessionId,
      sessiontype: session.sessionType,
      customerId: session.customerId,
      buyerAccount: session.buyerAccountIdentifier,
      originApplication: session.originatingApplication,
    });
  }

  public recordUserPaymentRules(paymentRules: PaymentRulesResponseV1): void {
    datadogRum.setUserProperty('paymentRules', {
      requirecvv: paymentRules.settings.payments.requireCVV,
      acceptach: paymentRules.settings.payments.acceptACH,
      acceptcreditcard: paymentRules.settings.payments.acceptCreditCard,
      allowablecardbrands: paymentRules.settings.payments.allowableCardBrands,
      acceptdirectdebit: paymentRules.settings.payments.acceptDirectDebit,
      allowsurcharging: paymentRules.settings.surcharge.allowSurcharging,
      restrictbycardtype: paymentRules.settings.surcharge.restrictByCardType,
      restrictbyzipcode: paymentRules.settings.surcharge.restrictByZipCode,
      zerodollarpaymentsenabled:
        paymentRules.settings.generalPaymentSettings.zeroDollarPaymentsEnabled,
      shortpaynotificationemails:
        paymentRules.settings.shortAndOverPaySettings
          .shortPayNotificationEmails,
      allowdisputereason:
        paymentRules.settings.shortAndOverPaySettings.allowDisputeReason,
      allowfreeforreason:
        paymentRules.settings.shortAndOverPaySettings.allowFreeFormReason,
      allowshortpay:
        paymentRules.settings.shortAndOverPaySettings.allowShortPay,
    });
  }

  public recordUserPayment(payment: SessionPayment): void {
    let isShortpay = false;
    let isOverpay = false;

    payment.transactions[0].payables.forEach((payable) => {
      if (payable.isOverPay) {
        isOverpay = true;
      }
      if (payable.isShortPay) {
        isShortpay = true;
      }
    });
    datadogRum.setUserProperty('payment', {
      method: payment.transactions[0].paymentAccount.paymentMethod ?? '',
      surcharge: payment.transactions[0].totalSurcharge !== 0,
      discount: payment.transactions[0].totalDiscount !== 0,
      shortpay: isShortpay,
      overpay: isOverpay,
      scheduled: payment.transactions[0].scheduled,
    });
  }

  logError(error: unknown, context?: Record<string, unknown>): void {
    if (!this.initialized) {
      return;
    }

    datadogRum.addError(error, context);
  }
}
